import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './main-view/dashboard/dashboard.component';
import { HeaderComponent } from './main-view/header/header.component';
import { StatisticsComponent } from './main-view/dashboard/statistics/statistics.component';
import { AccountTableComponent } from './main-view/dashboard/account-table/account-table.component';
import { UserDetailsComponent } from './main-view/dashboard/user-details/user-details.component';
import { SearchInputComponent } from "./share/table/search-input/search-input.component";
import { CommentsPipe } from './pipes/comments.pipe';
import { SerialNoPipe } from './pipes/serial-number.pipe';
import { AverageReportsPipe } from './pipes/average-reports.pipe';
import { AcExpirationPipe } from './pipes/ac-expiration.pipe';
import { InstrumentModelPipe } from './pipes/instrument-model.pipe';
import { ReportsLeftPipe } from './pipes/reports-left.pipe';
import { DiskSpacePipe } from './pipes/disk-space.pipe';
import { AcStatusPipe } from './pipes/ac-status.pipe';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
  ScrollingModule
} from "@angular/cdk/scrolling";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthInterceptor } from "./auth/auth.interceptor";
import {
  MsalRedirectComponent,
  MsalModule,
} from '@azure/msal-angular';
import { ErrorInterceptor } from "./auth/error.interceptor";
import { MsalConfigurationModule } from "./auth/msal-configuration.module";
import { HighchartsChartModule } from "highcharts-angular";
import { NgOptimizedImage } from "@angular/common";
import { ModifyUserComponent } from "./main-view/modify-user/modify-user.component";
import { FormFieldComponent } from "./share/components/form-field/form-field.component";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { CdkTableModule } from "@angular/cdk/table";
import { FilterStringListPipe } from "./main-view/modify-user/filter-string-list.pipe";
import { ImageUploadComponent } from "./share/components/image-upload/image-upload.component";
import { ImageCropperDialogComponent } from "./share/components/image-cropper-dialog/image-cropper-dialog.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { MatDialogModule } from "@angular/material/dialog";
import {
  ImageSizeErrorDialogComponent
} from "./share/components/image-size-error-dialog/image-size-error-dialog.component";
import { NotificationComponent } from './share/components/notification/notification.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatFormFieldControl, MatFormFieldModule} from "@angular/material/form-field";
import { ModalComponent } from './share/modal/modal.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    StatisticsComponent,
    AccountTableComponent,
    UserDetailsComponent,
    SearchInputComponent,
    AcExpirationPipe,
    AverageReportsPipe,
    CommentsPipe,
    InstrumentModelPipe,
    ReportsLeftPipe,
    SerialNoPipe,
    DiskSpacePipe,
    AcStatusPipe,
    ModifyUserComponent,
    FormFieldComponent,
    FilterStringListPipe,
    ImageUploadComponent,
    ImageCropperDialogComponent,
    ImageSizeErrorDialogComponent,
    NotificationComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    BrowserAnimationsModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    MsalModule,
    MsalConfigurationModule.forRoot(),
    HighchartsChartModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    CdkTableModule,
    ImageCropperModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule
  ],
  providers: [
    // HTTP interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
