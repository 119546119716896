import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../../env/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyRepository {

  constructor(private httpClient: HttpClient) { }

  getCompanies(): Observable<string[]> {
    return this.httpClient.get<{ name: string }[]>(`${environment.config.apiURL}/companies`).pipe(
        map(companies => companies.map(company => company.name))
    );
  }

  addCompany(name: string): Observable<{ name: string }> {
    return this.httpClient.post<{ name: string }>(`${environment.config.apiURL}/companies`, { name: name })
  }
}
