import {Injectable} from "@angular/core";
import {CompanyRepository} from "../repository/company.repository";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CompanyService {

  constructor(private companyRepository: CompanyRepository) { }

  getCompanies(): Observable<string[]> {
    return this.companyRepository.getCompanies();
  }

  addCompany(name: string): Observable<{ name: string }> {
    return this.companyRepository.addCompany(name);
  }
}
