import { Pipe, PipeTransform } from '@angular/core';
import { Account } from '../share/account/models/account.model';

@Pipe({
  name: 'comments',
})
export class CommentsPipe implements PipeTransform {
  transform(account: Account): string {
    return account.comments ? account.comments : '-';
  }
}
