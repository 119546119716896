import { Pipe, PipeTransform } from '@angular/core';
import { Account } from '../share/account/models/account.model';

@Pipe({
  name: 'reportsLeft',
})
export class ReportsLeftPipe implements PipeTransform {
  transform(account: Account): number | string {
    if (account.role === 'ADMIN') {
      return 'N/A';
    } else {
      return account.expirationDate ? 'Unlimited' : account.reportLeft;
    }
  }
}
