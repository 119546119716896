import { Component, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Image cropper dialog component
 */
@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent {

  /**
   * Path to cropped image
   */
  croppedImage: any = '';

  /**
   * Create component instance
   * @param {MatDialogRef<ImageCropperDialogComponent>} dialogRef Get dialog reference
   * @param data Inject dialog data
   */
  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  /**
   * Crop image handler
   * @param event crop event
   */
  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  /**
   * Close dialog
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }
}

/**
 * Define structure of DialogData object
 */
interface DialogData {
  /**
   * Dialog title
   */
  title: string;
  /**
   * Event of image
   */
  imageChangedEvent: Event;
}
