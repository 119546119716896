<div class="toolbar">
  <div class="search-container">
    <app-search-input
      [placeholder]="'Search by user/device name'"
      (changedSearchText)="onChangedSearchText($event)">
    </app-search-input>
  </div>
  <div class="spacer"></div>
  <div class="right-toolbar">
    <div class="filters">
      <span class="filter-text">Filter by:</span>
      <div class="clear-filter-button dot-wrapper" (click)="clearFilters()">
        <img src="assets/icons/revert.svg" alt="reset filters" height="15" width="15" class="dot">
      </div>
      <ng-container *ngFor="let status of AccountStatusEnum | keyvalue">
        <div *ngIf="[AccountStatusEnum.STATUS1, AccountStatusEnum.STATUS2, AccountStatusEnum.STATUS3, AccountStatusEnum.STATUS4, AccountStatusEnum.STATUS5, AccountStatusEnum.STATUS6].includes(AccountStatusEnum[status.key])"
             class="dot-wrapper"
             [title]="'Filter by ' + (status.key | acStatus)"
             (click)="filterByStatus(status.value)"
             [ngStyle]="{'opacity': filterStatuses.includes(status.value) || !filterStatuses ? 1 : 0.3}">
          <span class="dot" [ngClass]="status.key.toLowerCase() + '-color'"></span>
        </div>
      </ng-container>
    </div>
    <div class="buttons">
      <img src="assets/icons/excel.svg" height="30" width="30" alt="excel icon" class="excel-icon" (click)="downloadXLSX()">
      <button class="classic-button" (click)="addUser()">
        <img src="assets/icons/plus.svg" alt="" height="15" width="15" class="plus-icon">
        <span>Add user</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="accounts$ | async as accounts" class="account-table-container">
  <div class="account-table-row head">
    <div class="account-table-col"
         *ngFor="let accountTableColumn of ACCOUNT_TABLE_COLUMNS"
         (click)="accountTableColumn.orderKey ? sort(accountTableColumn.orderKey) : ''">
      {{accountTableColumn.header}}
      <div *ngIf="accountTableColumn.orderKey">
        <div *ngIf="accountTableColumn.orderKey !== sortBy">-</div>
        <div *ngIf="accountTableColumn.orderKey === sortBy && sortOrder === SortOrderEnum.DESC" [style.margin-top.px]="-3" [style.transform]="'rotate(180deg)'">^</div>
        <div *ngIf="accountTableColumn.orderKey === sortBy && sortOrder === SortOrderEnum.ASC">^</div>
      </div>
    </div>
  </div>
  <cdk-virtual-scroll-viewport class="virtual-scroll" itemSize="35"
  (scrolledIndexChange)="loadMoreData()">
    <li class="account-table-row" *cdkVirtualFor="let account of accounts" (click)="selectAccount(account)"
        [class.selected-row]="account.id === selectedAccountId" [class.account-table-row-disabled]="account.isDisabled">
      <div class="account-table-col" *ngFor="let accountTableColumn of ACCOUNT_TABLE_COLUMNS">
        <p *ngIf="accountTableColumn.dataKey !== ''">
          {{account[accountTableColumn.dataKey] ? account[accountTableColumn.dataKey] : "-"}}
        </p>
        <p *ngIf="accountTableColumn.header === AccountTableHeadersEnum.CREATION_DATE">
          {{account[AccountPropertyEnum.CREATION_DATE] | date: 'dd MMM y HH:mm' : 'UTC'}}
        </p>
        <p *ngIf="accountTableColumn.header === AccountTableHeadersEnum.A_C_EXPIRATION">
          {{account[AccountPropertyEnum.EXPIRATION_DATE] ? (account[AccountPropertyEnum.EXPIRATION_DATE] | date: 'dd MMM y HH:mm' : 'UTC') : "-"}}
        </p>
        <p *ngIf="accountTableColumn.header === AccountTableHeadersEnum.DISK_SPACE">
            {{ account | diskSpace }}
        </p>
        <div *ngIf="accountTableColumn.header === AccountTableHeadersEnum.A_C_STATUS" class="dot-wrapper">
          <span class="dot"
                [ngClass]="AccountStatusEnum[account[AccountPropertyEnum.STATUS]]?.toLowerCase() + '-color'"
                [title]="AccountStatusEnum[account[AccountPropertyEnum.STATUS]] | acStatus">
          </span>
        </div>
        <span *ngIf="accountTableColumn.header === AccountTableHeadersEnum.EDIT">
          <img ngSrc="assets/icons/edit_icon.svg" alt="" height="19" width="21" (click)="editUser(account.id)">
        </span>
      </div>
    </li>
    <div *ngIf="!isLoaded" class="spinner-div">
      <mat-spinner class="list-spinner"></mat-spinner>
    </div>
  </cdk-virtual-scroll-viewport>
  <div class="filter-text page-count">
    {{(accounts$ | async).length}}/{{totalCount}}
  </div>
</div>
