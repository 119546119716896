<app-notification></app-notification>
<ng-container *ngIf="(user$ | async) as user">
  <div class="main-view-container">
    <div class="header-container">
      <app-header [user]="user" class="header"></app-header>
    </div>
    <div class="dashboard-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
