import { Component, Input } from '@angular/core';
import { Account } from "../../../share/account/models/account.model";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent {
  @Input()
  account: Account;
  @Input()
  reportsDates: Date[];
}
