import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './auth/auth.guard';
import { ModifyUserComponent } from "./main-view/modify-user/modify-user.component";
import { RoutesEnum } from "./share/enums/routes.enum";
import { DashboardComponent } from "./main-view/dashboard/dashboard.component";

const routes: Routes = [
  {
    path: RoutesEnum.EMPTY,
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: RoutesEnum.MODIFY_USER,
        component: ModifyUserComponent
      },
      {
        path: RoutesEnum.MODIFY_USER + RoutesEnum.ID_PARAMETER,
        component: ModifyUserComponent
      }
    ]
  },
  {
    path: RoutesEnum.WILDCARD,
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
