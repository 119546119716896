import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent {

  /**
   * Label name
   */
  @Input()
  label: string;

  @Input()
  unit = '';

  /**
   * Create class instance
   */
  constructor() { }

}
