import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * Gul search input component
 */
@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  /**
   * Placeholder name
   */
  @Input()
  placeholder: string;
  /**
   * Emit search value
   */
  @Output()
  changedSearchText: EventEmitter<string> = new EventEmitter();
  /**
   * Search value
   */
  @Input() searchValue?: string;
  /**
   * Search text
   */
  _searchText: string;

  /**
   * Assign search text value
   */
  ngOnInit(): void {
    this._searchText = this.searchValue;
  }

  /**
   * Emit search text
   * @param {string} searchText Search text
   */
  onSearchTextChange(searchText: string): void {
    this.changedSearchText.emit(searchText);
  }
}
