import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStringList'
})
export class FilterStringListPipe implements PipeTransform {

  transform(stringList: string[], filterString: string): string[] {
    return stringList?.filter(stringFromList => stringFromList.toLowerCase().includes(filterString?.trim().toLowerCase()));
  }

}
