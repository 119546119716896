<div class="title-container">
  <div class="rectangle"></div>
  <h4 class="gul-font-color title">User Details</h4>
</div>

<div class="content">
  <div class="avatar-container">
    <img class="avatar" [src]="account.avatar?.length ? account.avatar : '/assets/images/no-avatar.jpg'" alt="" height="152" width="152">
  </div>
  <div class="user-details-container">
    <div class="row">
      <div class="col details-name">
        <h5 class="label">User/Device Name</h5>
        <div class="value">
          {{
          account.displayName
            ? account.displayName
            : account.firstName + " " + account.lastName
          }}
        </div>
      </div>
      <div class="col details-company-name">
        <h5 class="label">Company Name</h5>
        <div class="value">
          {{ account.company }}
        </div>
      </div>
      <div class="col details-serial-number">
        <h5 class="label">Serial No.</h5>
        <div class="value">
          {{ account | serialNo }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col cumulative-reports">
        <h5 class="label">Cumulative Reports Generated</h5>
        <div class="value">
          {{ account.reportGenerated ? account.reportGenerated : 0}}
        </div>
      </div>
      <div class="col average-reports">
        <h5 class="label">Average Monthly Reports In Last 12 months</h5>
        <div class="value">
          {{ reportsDates | averageReports | number:'1.0-2' }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col disk-space">
        <h5 class="label">Disk Space [GB]</h5>
        <div class="value">
          {{
          (account.diskSpace.max
            ? (account.diskSpace.used + " / " + account.diskSpace.max)
            : account.diskSpace.used)
          }}
        </div>
      </div>
      <div class="col reports-left">
        <h5 class="label">Reports Left</h5>
        <div class="value">
          {{ account | reportsLeft }}
        </div>
      </div>
      <div class="col expiration-date">
        <h5 class="label">Expiration Date</h5>
        <div class="value">
          {{ account | acExpiration }}
        </div>
      </div>
      <div class="col creation-date">
        <h5 class="label">Creation Date</h5>
        <div class="value">
          {{ account.creationDate | date:'dd MMM yyyy' }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col login">
        <h5 class="label">Login</h5>
        <div class="value">
          {{ account.email }}
        </div>
      </div>
      <div class="col average-reports">
        <h5 class="label">Comments</h5>
        <div class="value">
          {{ account | comments }}
        </div>
      </div>
      <div class="col average-reports">
        <h5 class="label">Forwarding Email</h5>
        <div class="value">
          {{ account.forwardingEmail ? account.forwardingEmail : '-' }}
        </div>
      </div>
    </div>
  </div>
</div>
