import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, filter, Observable, switchMap, take, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";
import {
  AccountInfo,
  AuthenticationResult,
  InteractionStatus,
  SilentRequest
} from "@azure/msal-browser";
import {environment as env} from "../../env/environment";
import {jwtDecode} from "jwt-decode";
import {UserModel} from "./user.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authToken: string;
  private msalAccountInfo: AccountInfo;
  private isMsalLoggedIn: boolean = false;

  private _user$ = new BehaviorSubject<UserModel>(null);
  get user$() {
    return this._user$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) { }

  init() {
    this.msalService.initialize().pipe(
        switchMap(() => this.msalBroadcastService.inProgress$),
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        tap(() => this.setMsalAccount()),
        switchMap(() => this.isMsalLoggedIn
            ? this.msalService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as SilentRequest)
            : this.login()),
        take(1),
        filter(value => !!value),
        switchMap(({ idToken }: AuthenticationResult) => this.http.post<any>(`${env.config.apiURL}/auth/login`, { idToken })),
        tap(({ token }) => this.setUser(token)),
        switchMap(() => this.http.get<string>(`${env.config.apiURL}/avatar`))
    ).subscribe(avatarUrl => this._user$.next({ ...this._user$.value, avatar: avatarUrl }));
  }

  private setMsalAccount() {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts && accounts.length) {
      this.isMsalLoggedIn = true;
      this.msalAccountInfo = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(this.msalAccountInfo);
    }
  }

  private setUser(token: any) {
    this.authToken = token;
    const payload = jwtDecode(token) as any;
    this._user$.next({
      fullName: payload.displayName,
      email: payload.email,
      role: payload.role,
    });
  }

  private clearData() {
    this.authToken = null;
    this._user$.next(null);
  }

  getAuthToken() {
    return this.authToken;
  }

  login() {
    this.clearData();
    return this.msalService.loginRedirect();
  }

  logout(): Observable<void> {
    this.clearData();
    return this.msalService.logoutRedirect();
  }
}
