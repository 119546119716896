import {Component} from '@angular/core';
import {AccountService} from "../../share/account/service/account.service";
import {Observable} from "rxjs";
import {Account} from "../../share/account/models/account.model";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  selectedAccount$: Observable<Account>;
  reportsDates$: Observable<Date[]>;

  constructor(private accountService: AccountService) {
  }

  getAccount($event: number) {
    this.selectedAccount$ = this.accountService.getAccount({ id: $event });
    this.reportsDates$ = this.accountService.getStatistics({ id: $event });
  }
}
