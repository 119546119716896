<div class="background">
  <div class="wrapper">
    <div class="header">
      <h3 class="label">{{label}}</h3>
      <div class="close" (click)="close.emit()">
        <span>
          <img src="assets/icons/xmark.svg" width="20" height="20">
        </span>
      </div>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
