import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Image size error dialog component
 */
@Component({
  selector: 'app-image-size-error-dialog',
  templateUrl: './image-size-error-dialog.component.html',
  styleUrls: ['./image-size-error-dialog.component.scss']
})
export class ImageSizeErrorDialogComponent {
  /**
   * Create component instance
   * @param {MatDialogRef<ImageSizeErrorDialogComponent>} dialogRef Get dialog reference
   */
  constructor(
    public dialogRef: MatDialogRef<ImageSizeErrorDialogComponent>,
  ) { }
}
