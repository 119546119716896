import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ImageSizeErrorDialogComponent} from "../image-size-error-dialog/image-size-error-dialog.component";
import {FileUploaderService} from "../../uploader/file-uploader.service";
import {AccountService} from "../../account/service/account.service";

/**
 * Define max size of file
 */
const MAX_FILE_SIZE = 200000;

/**
 * Image upload view component
 */
@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  /**
   * Get file input element
   */
  @ViewChild('fileInput') fileInput: ElementRef;

  /**
   * Image url
   */
  @Input()
  url: string;

  /**
   * Account ID
   */
  @Input() accountId: number;
  /**
   * Emit changed image url
   */
  @Output()
  imgChange: EventEmitter<File> = new EventEmitter();

  /**
   * Create component instance
   * @param {MatDialog} dialog Get dialog data
   */
  constructor(
    public dialog: MatDialog
  ) { }

  /**
   * Change file
   * @param {Event} event Event data
   */
  handleInputFileChange(event: Event): void {
    const files = (<HTMLInputElement> event.target).files;
    if (files && files[0]) {
      const file = files[0];
      if (file.size >= MAX_FILE_SIZE) {
        return this.openImageSizeErrorDialog();
      }
      this.imgChange.emit(file);
    } else {
      this.imgChange.emit();
    }
  }

  /**
   * Remove uploaded image
   */
  removeImage(): void {
    this.imgChange.emit();
  }

  /**
   * Open dialog with image
   */
  private openImageSizeErrorDialog(): void {
    this.dialog.open(ImageSizeErrorDialogComponent);
  }
}
