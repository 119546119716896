<h2 mat-dialog-title class="gul-font-color uk-text-center">{{data.title}}</h2>
<div mat-dialog-content class="uk-remove-padding">
  <div class="uk-width-1-1">
    <image-cropper style="max-height: 300px" class="gul-background-color uk-flex"
                   [imageChangedEvent]="data.imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1"
                   [roundCropper]="true" format="jpeg" (imageCropped)="imageCropped($event)"></image-cropper>
  </div>
</div>
<div mat-dialog-actions class="uk-width-1-1 uk-flex uk-flex-center uk-margin-medium-top">
  <button class="uk-button gul-button gul-button-danger" (click)="onCancelClick()" style="width: 100px;">CANCEL</button>
  <button [mat-dialog-close]="croppedImage"
          class="uk-button gul-button gul-button-default uk-margin-medium-left" style="width: 100px;">CONFIRM</button>
</div>
