<nav class="header-container">
  <div class="section section-left" routerLink="/">
    <img src="assets/icons/logo.svg" alt="logo" width="36" height="36">
    <span>Scanning Studio Admin</span>
  </div>
  <div class="section section-right" (click)="toggleDropdown(); $event.stopPropagation();">
    <span>{{user.fullName}} - Admin</span>
    <div class="avatar-container">
      <img [src]="user.avatar ? user.avatar : 'assets/images/no-avatar.jpg'" class="profile-avatar" />
      <div class="dropdown" [style.visibility]="isDropdownVisible ? 'visible' : 'hidden'" #dropdown>
        <ul class="dropdown-ul">
          <li class="menu-link" (click)="redirectToScanningStudio()">
            <img src="assets/icons/home.svg" alt="home" width="20" height="20">
            <span>Go to Scanning Studio</span>
          </li>
          <li class="menu-link" (click)="aboutModal = true">
            <img src="assets/icons/circle-info.svg" alt="info" width="20" height="20">
            <span>About</span>
          </li>
          <li class="menu-link" (click)="logout()">
            <img src="assets/icons/sign-out.svg" alt="sign-out" width="20" height="20">
            <span>Sign out</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<app-modal *ngIf="aboutModal" [label]="'About'" (close)="aboutModal = false;">
  <div class="modal">
    <div class="content">
      <div class="logo-wrapper">
        <img class="logo" src="assets/logo-blue.svg">
      </div>
      <h3>Scanning Studio</h3>
      <small>Version {{version}}</small>
      <div>Copyright &copy; {{year}} Guided Ultrasonics Ltd.</div>
      <div>All rights reserved</div>
    </div>
  </div>
</app-modal>
