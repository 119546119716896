export enum FormControlNameEnum {
  ROLE = 'role',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DISPLAY_NAME = 'displayName',
  COMPANY = 'company',
  EMAIL = 'email',
  FORWARD_EMAIL = 'forwardEmail',
  DISK_SPACE = 'diskSpace',
  REPORT_LEFT = 'reportLeft',
  EXPIRATION_DATE = 'expirationDate',
  INSTRUMENT_MODEL = 'instrumentModel',
  SERIAL_NUMBER = 'serialNumber',
  COMMENTS = 'comments',
  LOCAL_ACCOUNT = 'localAccount',
  AVATAR = 'avatar',
}
