export const environment= {
  production: true,
  config: {
    // apiURL: 'http://localhost:8080',
    apiURL: 'https://qsr-api-dev.ukwest.cloudapp.azure.com',
    scanningStudioUrl: 'https://devqsr.gulndt.com',

    msalConfig: {
      clientId: '3d3ce0ae-0e9d-479b-8a87-35858b25c814',
      authority: 'https://gulndt.b2clogin.com/gulndt.onmicrosoft.com/B2C_1_SignIn_QSR',
      knownAuthorities: ['gulndt.b2clogin.com'],
      redirectUri: 'https://admindev-scanning.gulndt.com',
      postLogoutRedirectUri: 'https://admindev-scanning.gulndt.com',
      // redirectUri: 'http://localhost:4200',
      // postLogoutRedirectUri: 'http://localhost:4200',
      scopes: ['https://gulndt.onmicrosoft.com/api/api.read'],
      Graph_endpoint: 'https://graph.microsoft.com/v1.0/me',
    },
    blobConfig: {
      dataContainer: 'data',
      avatarContainer: 'avatars'
    }
  }
};
