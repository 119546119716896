export enum AccountSortKeysEnum {
  ID = 'id',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DISPLAY_NAME = 'displayName',
  EMAIL = 'email',
  COMPANY = 'company',
  ROLE = 'userRole',
  CREATION_DATE = 'createdAt',
  INSTRUMENT_MODEL = 'instrument',
  EXPIRATION_DATE = 'expirationDate',
  REPORT_GENERATED = 'reportsGenerated',
  REPORT_LEFT = 'reportLeft',
  DISK_SPACE = 'discSpace',
  COMMENTS = 'comments',
  STATUS = 'status',
  SERIAL_NUMBER = 'serialNumber'
}
