import {Component, OnInit} from '@angular/core';
import {AuthService} from "./auth/auth.service";
import {Observable} from "rxjs";
import {UserModel} from "./auth/user.model";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public user$: Observable<UserModel>;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.user$ = this.authService.user$;
    this.authService.init();
  }
}
