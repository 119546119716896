<div class="table-container">
  <app-account-table (newAccountSelected)="getAccount($event)"></app-account-table>
</div>
<div *ngIf="(selectedAccount$ | async) as account" class="details-container">
  <ng-container *ngIf="(reportsDates$ | async) as reportDates">
    <div class="left-side">
      <app-user-details [account]="account" [reportsDates]="reportDates"></app-user-details>
    </div>
    <div class="right-side">
      <app-statistics [reportsDates]="reportDates"></app-statistics>
    </div>
  </ng-container>
</div>
