import {AccountService} from "../../share/account/service/account.service";
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export class UniqueEmailValidator {
  static createValidator(accountService: AccountService, localAccountControlName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return accountService.checkIfEmailIsUnique(control.value).pipe(
        map((status: number) => {
          switch(status) {
            case(200): return { emailAlreadyExists: true };
            case(404): return null;
            case(409): return control.parent.get(localAccountControlName).value
              ? null
              : { accountAlreadyExistInEntra: true };
            default: return null;
          }
        })
      );
    };
  }
}
