export enum AccountTableHeadersEnum {
  USER_DEVICE_NAME = 'User/Device Name',
  COMPANY_NAME = 'Company Name',
  USER_ROLE = 'User Role',
  CREATION_DATE = 'Creation Date',
  INSTRUMENT_MODEL = 'Instrument Model',
  A_C_EXPIRATION = 'A/C Expiration',
  N_REPORT_GENERATED = '#Report Generated',
  N_REPORT_LEFT = '#Report Left',
  DISK_SPACE = 'Disk Space',
  COMMENTS = 'Comments',
  A_C_STATUS = 'A/C Status',
  EDIT = 'Edit'
}
