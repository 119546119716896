import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Account } from '../share/account/models/account.model';
import { AccountPropertyEnum } from '../share/account/enums/account-property.enum';
import { DiskSpacePropertyEnum } from '../share/account/enums/disk-space-property.enum';
import { RolesEnum } from '../share/enums/roles.enum';

@Pipe({
  name: 'diskSpace',
})
export class DiskSpacePipe implements PipeTransform {
  transform(account: Account): string {
    if (account.role === RolesEnum.ADMIN) {
      return 'N/A';
    } else {
      if (account.expirationDate) {
        return 'N/A';
      } else {
        return `${formatNumber(account[AccountPropertyEnum.DISK_SPACE][DiskSpacePropertyEnum.USED], "en-GB", "1.0-2")}/${formatNumber(account[AccountPropertyEnum.DISK_SPACE][DiskSpacePropertyEnum.MAX], "en-GB", "1.0-2")} GB`;
      }
    }
  }
}
