import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'averageReports',
})
export class AverageReportsPipe implements PipeTransform {
  transform(reportsDates: Date[]):  number {
    var date = new Date();
    var currentMonthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastYearStart = new Date(date.getFullYear() - 1, date.getMonth(), 1);
    var lastYearReports = reportsDates.filter( date => {
        return date >= lastYearStart && date <= currentMonthStart;
    })

    return lastYearReports.length / 12;
  }
}
