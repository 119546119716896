import { Pipe, PipeTransform } from '@angular/core';
import { Account } from '../share/account/models/account.model';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'acExpiration',
})
export class AcExpirationPipe implements PipeTransform {
  transform(account: Account): Date | string {
    if (account.role === 'ADMIN') {
      return 'N/A';
    } else {
      if (account.expirationDate) {
        return formatDate(account.expirationDate, 'dd MMM yyyy', 'en-GB')
      } else {
        return 'Unlimited';
      }
    }
  }
}
