import {Component, Input} from '@angular/core';
import * as HighStock from 'highcharts/highstock';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent {
  HighStock = HighStock;

  @Input()
  reportsDates: Date[] = [];

  statisticsChart: HighStock.StockChart;

  statisticsChartOptions: HighStock.Options = {
    chart: this.getChartOptions(),
    title: this.getTitleOptions(),
    xAxis: this.getXAxisOptions(),
    yAxis: this.getYAxisOptions(),
    plotOptions: this.getPlotOptions(),
    rangeSelector: this.getRangeSelectorOptions(),
    series: this.getInitialSeriesOptions(),
    credits: this.getCreditsOptions(),
    tooltip: this.getTooltipOptions()
  };

  YEAR_IN_MS = 365 * 24 * 3600 * 1000;

  setNewSeries() {
    this.statisticsChart.series[0].update({
      ...this.statisticsChartOptions.plotOptions.series[0],
      data: this.reportsDates.map(date => [date.getTime(), 1])
    });
  }

  calculateAverage(isMonthly: boolean, hasLimits: boolean, min?: number, max?: number): number {
    const yearCounts = new Map<number, number>()
    const monthCounts = new Map<string, number>()
    let sum = 0;

    this.reportsDates.forEach(date => {
      if (!hasLimits || (date.getTime() >= min && date.getTime() < max)) {
        const year = date.getFullYear();
        const month = date.getMonth();
        if (yearCounts.has(year)) {
          yearCounts.set(year, yearCounts.get(year) + 1);
        } else {
          yearCounts.set(year, 1);
        }
        if (monthCounts.has(year.toString() + '/' + month.toString())) {
          monthCounts.set(year.toString() + '/' + month.toString(), monthCounts.get(year.toString() + '/' + month.toString()) + 1)
        } else {
          monthCounts.set(year.toString() + '/' + month.toString(), 1);
        }
        sum++;
      }
    });

    const nYears = yearCounts.size;
    const nMonths = monthCounts.size;

    let annualAverage = sum / nYears;
    let monthlyAverage = sum / nMonths;
    return isMonthly ? monthlyAverage : annualAverage;
  }

  addAverageToChart(range: number) {
    const lastDateTime = this.reportsDates[this.reportsDates.length - 1].getTime();
    const isMonthly = range <= this.YEAR_IN_MS;
    const average = this.calculateAverage(isMonthly, true, lastDateTime - range, lastDateTime + 1);
    this.statisticsChart.update({
      yAxis: {
        ...this.statisticsChartOptions.yAxis,
        plotLines: [{
          color: 'rgb(0, 40, 74)',
          value: average,
          width: 4,
          label: {
            text: 'Average: ' + average.toFixed(2),
            style: {
              color: 'rgb(0, 40, 74)',
              fontWeight: '700'
            },
            y: -8
          },
          zIndex: 5
        }]
      }
    });
  }

  getTitleOptions(): HighStock.TitleOptions {
    return {
      text: ''
    };
  }

  getXAxisOptions(): HighStock.XAxisOptions {
    const self = this;
    return {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b %y',
      },
      events: {
        afterSetExtremes: function (event) {
          self.statisticsChart.update({
            plotOptions: {
              series: {
                dataGrouping: {
                  enabled: true,
                  forced: true,
                  units: event.max - event.min <= self.YEAR_IN_MS ? [[ 'month', [1] ]] : [[ 'year', [1] ]]
                }
              }
            }
          });
          self.addAverageToChart(event.max - event.min);
        }
      }
    };
  }

  getYAxisOptions(): HighStock.YAxisOptions {
    return {
      title: {
        text: 'Reports'
      },
      plotLines: []
    };
  }

  getChartOptions(): HighStock.ChartOptions {
    const self = this;
    return  {
      style: {
        fontFamily: 'sans-serif',
        color: 'rgb(0, 40, 74)',
        fontWeight: '500',
        fontSize: '20'
      },
      zooming: {
        type: 'x'
      },
      events: {
        load: function () {
          self.statisticsChart = this;
          self.setNewSeries();
        }
      }
    };
  }

  getInitialSeriesOptions(): HighStock.SeriesOptionsType[] {
    return [
      {
        name: 'Reports',
        data: [[]],
        type: 'column',
        color: '#3ac8fc',
        showInLegend: false
      }
    ];
  }

  getPlotOptions(): HighStock.PlotOptions {
    return {
      column: {
        centerInCategory: true
      },
      series: {
        dataGrouping: {
          enabled: true,
          forced: true,
          units: [
            [
              'year',
              [ 1 ]
            ]
          ]
        }
      }
    };
  }

  getRangeSelectorOptions(): HighStock.RangeSelectorOptions {
    return {
      enabled: true,
      inputEnabled: false,
      buttonPosition: {
        align: 'right'
      },
      buttons: [
        {
          type: 'month',
          count: 3,
          text: '3m',
          title: 'View 3 months'
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
          title: 'View 6 months'
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
          title: 'View 1 year'
        },
        {
          type: 'all',
          text: 'All',
          title: 'View all'
        }
      ]
    };
  }

  getCreditsOptions(): HighStock.CreditsOptions {
    return  {
      enabled: false
    };
  }

  private getTooltipOptions(): HighStock.TooltipOptions {
    return {
      valueDecimals: 2
    }
  }
}
