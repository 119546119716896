import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { PageNotification } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notifyRequest = new ReplaySubject<PageNotification>();

  notifyRequest$ = this.notifyRequest.asObservable();

  notify(notification: PageNotification) {
    this.notifyRequest.next(notification);
  };
}
