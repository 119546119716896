import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { RolesEnum } from "../share/enums/roles.enum";
import {filter, tap} from "rxjs";

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.user$.pipe(
    filter(user => !!user),
    map(user => user.role === RolesEnum.ADMIN),
    tap(isAdmin => isAdmin ? '' : authService.logout())
  );
}
