import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AccountDto} from "../models/account.dto";
import {Account} from "../models/account.model";
import {BehaviorSubject, Observable, of, shareReplay, switchMap, tap} from "rxjs";
import {DiskSpace} from "../models/disk-space.model";
import {AccountFetchOptions} from "../models/account-fetch-options.model";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../env/environment";
import {FileUploaderService} from "../../uploader/file-uploader.service";

@Injectable({
  providedIn: 'root'
})
export class AccountRepository {

  private accountsSource = new BehaviorSubject<Account[]>([]);

  accounts$ = this.accountsSource.asObservable();

  constructor(
    private httpClient: HttpClient,
    private fileUploadService: FileUploaderService
  ) { }

  getAccount(fetchOptions: AccountFetchOptions): Observable<Account> {
    return this.httpClient.get<AccountDto>(`${environment.config.apiURL}/account/${fetchOptions.id}`).pipe(
      map(res => res['user'][0]),
      map(accountDto => this.mapAccountDtoToModel(accountDto))
    );
  }

  /**
   * Gets accounts and puts them in the accountsSource subject. Returns total count
   * @param fetchOptions
   * @param resetPage
   */
  getAccounts(fetchOptions: AccountFetchOptions, resetPage: boolean): Observable<number> {
    return this.httpClient.get<AccountDto[]>(
      `${environment.config.apiURL}/accounts?page=${fetchOptions.page}&pageSize=${fetchOptions.pageSize}&name=${fetchOptions.searchText || ''}&status=${fetchOptions.filterStatuses}&${fetchOptions.sortBy}=${fetchOptions.sortOrder}`).pipe(
      map(accountsDto => accountsDto.map(accountDto => this.mapAccountDtoToModel(accountDto))),
      tap(accounts =>
        this.accountsSource.next(resetPage
          ? accounts
          : [
            ...this.accountsSource.value,
            ...accounts
          ]
        )
      ),
      map(accounts => accounts.length)
    );
  }

  createAccount(newAccount: Account, avatar?: File): Observable<any> {
    if (newAccount.reportLeft && newAccount.diskSpace.max) {
      newAccount.expirationDate = undefined;
    } else if (newAccount.expirationDate) {
      newAccount.reportLeft = undefined;
      newAccount.diskSpace.max = undefined;
    }

    return this.httpClient.post(`${environment.config.apiURL}/user`, {
      ...newAccount,
        maxReports: newAccount.reportLeft,
        maxDiskSpace: newAccount.diskSpace.max
    }).pipe(this.addAvatar(avatar));
  }

  editAccount(account: Account, accountId: number, avatar?: File): Observable<any> {
    if (Object.keys(account).length) {
      return this.httpClient.patch(`${environment.config.apiURL}/user/${accountId}`, account).pipe(this.addAvatar(avatar));
    }
    return of({ ...account, id: accountId }).pipe(this.addAvatar(avatar));
  }

  private addAvatar(avatar: File) {
    return (
      switchMap((account: Account) => {
        if (!avatar) {
          return of({});
        }
        return this.fileUploadService.getUploadAvatarLink(account.id, avatar.name.split('.').pop()).pipe(
          switchMap(response => this.fileUploadService.uploadToAzure(avatar, response['uploadURL'], account.id, avatar.name.split('.').pop()))
        );
      })
    );
  }

  getStatistics(fetchOptions: AccountFetchOptions): Observable<Date[]> {
    return this.httpClient.get<{ createdAt: string }[]>(`${environment.config.apiURL}/statistics?accountId=${fetchOptions.id}`).pipe(
      map(statisticsDto => statisticsDto.map(statistic => new Date(statistic.createdAt))
        .sort((a, b) => a.getTime() - b.getTime())
      ));
  }

  checkIfEmailIsUnique(email: string): Observable<number> {
    return this.httpClient.head(`${environment.config.apiURL}/identities?type=email&value=${email}`, {observe: 'response'}).pipe(
      map(res => res.status),
      catchError(err => of(err.status))
    );
  }

  mapAccountDtoToModel(accountDto: AccountDto): Account {
    return {
      id: accountDto.id,
      comments: accountDto.comments,
      company: accountDto.Company?.name ? accountDto.Company?.name : '',
      creationDate: new Date(accountDto.createdAt),
      diskSpace: this.mapDiskSpace(accountDto),
      expirationDate: accountDto.expirationDate ? new Date(accountDto.expirationDate) : null,
      instrumentModel: accountDto.instrumentModel,
      firstName: accountDto.firstName,
      lastName: accountDto.lastName,
      displayName: accountDto.displayName,
      email: accountDto.email,
      reportGenerated: accountDto.reportsGenerated,
      reportLeft: accountDto.maxReports ? accountDto.maxReports - accountDto.reportsGenerated : null,
      maxReports: accountDto.maxReports,
      role: accountDto.role,
      status: accountDto.status,
      serialNumber: accountDto.serialNumber,
      avatar: accountDto.avatar,
      isDisabled: accountDto.isDisabled,
      forwardingEmail: accountDto.forwardingEmail
    };
  }

  mapDiskSpace(accountDto: AccountDto): DiskSpace {
    return {
      used: parseFloat((accountDto.usedDiskSpace / 1000000000).toFixed(2)),
      max: accountDto.maxDiskSpace
    };
  }

  deleteAccount(accountId: number): Observable<any> {
    return this.httpClient.delete(`${environment.config.apiURL}/user/${accountId}`);
  }

  getFile() {
    const headers = new HttpHeaders({'Content-Type': 'text/csv'})
    return this.httpClient.get(`${environment.config.apiURL}/accounts/xlsx`, {
      headers: headers,
      responseType: 'blob'
    });
  }
}
