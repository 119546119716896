import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AccountService} from "../../../share/account/service/account.service";
import {Observable, take, tap} from "rxjs";
import {Account} from "../../../share/account/models/account.model";
import {AccountTableHeadersEnum} from "./account-table-headers.enum";
import {TableColumn} from "../../../share/table/models/table-column.model";
import {AccountPropertyEnum} from "../../../share/account/enums/account-property.enum";
import {DiskSpacePropertyEnum} from "../../../share/account/enums/disk-space-property.enum";
import {AccountStatusEnum} from "../../../share/account/enums/account-status.enum";
import {SortOrderEnum} from "../../../share/table/enums/sort-order.enum";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {Router} from "@angular/router";
import {RoutesEnum} from "../../../share/enums/routes.enum";
import {AccountSortKeysEnum} from "../../../share/account/enums/account-sort-keys.enum";

@Component({
  selector: 'app-account-table',
  templateUrl: './account-table.component.html',
  styleUrls: ['./account-table.component.scss']
})
export class AccountTableComponent implements OnInit, AfterViewInit{

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  accounts$: Observable<Account[]>

  ACCOUNT_TABLE_COLUMNS: TableColumn[] = [
    { header: AccountTableHeadersEnum.USER_DEVICE_NAME, dataKey: AccountPropertyEnum.DISPLAY_NAME, orderKey: AccountSortKeysEnum.DISPLAY_NAME },
    { header: AccountTableHeadersEnum.COMPANY_NAME, dataKey: AccountPropertyEnum.COMPANY, orderKey: AccountSortKeysEnum.COMPANY },
    { header: AccountTableHeadersEnum.USER_ROLE, dataKey: AccountPropertyEnum.ROLE, orderKey: AccountSortKeysEnum.ROLE },
    { header: AccountTableHeadersEnum.CREATION_DATE, dataKey: '', orderKey: AccountSortKeysEnum.CREATION_DATE },
    { header: AccountTableHeadersEnum.INSTRUMENT_MODEL, dataKey: AccountPropertyEnum.INSTRUMENT_MODEL, orderKey: AccountSortKeysEnum.INSTRUMENT_MODEL },
    { header: AccountTableHeadersEnum.A_C_EXPIRATION, dataKey: '', orderKey: AccountSortKeysEnum.EXPIRATION_DATE },
    { header: AccountTableHeadersEnum.N_REPORT_GENERATED, dataKey: AccountPropertyEnum.REPORT_GENERATED, orderKey: AccountSortKeysEnum.REPORT_GENERATED },
    { header: AccountTableHeadersEnum.N_REPORT_LEFT, dataKey: AccountPropertyEnum.REPORT_LEFT },
    { header: AccountTableHeadersEnum.DISK_SPACE, dataKey: '', orderKey: AccountSortKeysEnum.DISK_SPACE },
    { header: AccountTableHeadersEnum.COMMENTS, dataKey: AccountPropertyEnum.COMMENTS },
    { header: AccountTableHeadersEnum.A_C_STATUS, dataKey: '' },
    { header: AccountTableHeadersEnum.EDIT, dataKey: '' }
  ];

  page = 0;

  pageSize = 100;

  sortBy = AccountSortKeysEnum.CREATION_DATE;

  sortOrder = SortOrderEnum.DESC;

  /**
   * List of statuses to be filtered by
   */
  filterStatuses: AccountStatusEnum[] = [];

  searchText: string;

  isLoaded = false;

  totalCount = 0;

  @Output()
  newAccountSelected = new EventEmitter<number>();

  selectedAccountId: number;

  constructor(
    private accountService: AccountService,
    private router: Router
  ) {}

  ngOnInit() {
    this.accounts$ = this.accountService.accounts$.pipe(tap(() => this.isLoaded = true));
  }

  ngAfterViewInit() {
    this.getAccounts(true);
  }

  loadMoreData() {
    if (this.virtualScroll.getRenderedRange().end === this.virtualScroll.getDataLength() && this.page * this.pageSize < this.totalCount) {
      this.isLoaded = false;
      this.page++;
      this.getAccounts(false);
    }
  }

  getAccounts(resetPage: boolean) {
    if (resetPage) {
      this.page = 0;
      this.virtualScroll.scrollToIndex(0);
    }

    this.accountService.getAccounts({
      page: this.page,
      pageSize: this.pageSize,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      filterStatuses: this.filterStatuses,
      searchText: this.searchText
    }, resetPage).pipe(take(1)).subscribe(totalCount => {
      this.totalCount = resetPage ? totalCount : this.totalCount += totalCount;
    });
  }

  clearFilters() {
    this.filterStatuses = [];
    this.getAccounts(true);
  }

  filterByStatus(status: AccountStatusEnum) {
    if (this.filterStatuses.includes(status)) {
      this.filterStatuses = [];
    } else {
      this.filterStatuses = [status];
    }
    this.getAccounts(true);
  }

  onChangedSearchText(searchText: string) {
    this.searchText = searchText;
    this.getAccounts(true);
  }

  addUser() {
    void this.router.navigateByUrl(RoutesEnum.MODIFY_USER);
  }

  editUser(id: number) {
    void this.router.navigateByUrl(RoutesEnum.MODIFY_USER + '/' + id);
  }

  selectAccount(account: Account) {
    this.selectedAccountId = account.id;
    this.newAccountSelected.emit(account.id);
  }

  protected readonly AccountTableHeadersEnum = AccountTableHeadersEnum;
  protected readonly AccountPropertyEnum = AccountPropertyEnum;
  protected readonly DiskSpacePropertyEnum = DiskSpacePropertyEnum;
  protected readonly AccountStatusEnum = AccountStatusEnum;

  downloadXLSX() {
    this.accountService.getFile();
  }

  sort(sortBy: AccountSortKeysEnum) {
    if (this.sortBy === sortBy) {
      this.sortOrder = this.sortOrder === SortOrderEnum.DESC ? SortOrderEnum.ASC : SortOrderEnum.DESC;
    } else {
      this.sortBy = sortBy;
      this.sortOrder = SortOrderEnum.DESC;
    }
    this.getAccounts(true);
  }

  protected readonly SortOrderEnum = SortOrderEnum;
}
