export enum AccountPropertyEnum {
  ID = 'id',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DISPLAY_NAME = 'displayName',
  EMAIL = 'email',
  COMPANY = 'company',
  ROLE = 'role',
  CREATION_DATE = 'creationDate',
  INSTRUMENT_MODEL = 'instrumentModel',
  EXPIRATION_DATE = 'expirationDate',
  REPORT_GENERATED = 'reportGenerated',
  REPORT_LEFT = 'reportLeft',
  DISK_SPACE = 'diskSpace',
  COMMENTS = 'comments',
  STATUS = 'status',
  SERIAL_NUMBER = 'serialNumber'
}
