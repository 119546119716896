import { Injectable } from '@angular/core';
import {
  AnonymousCredential,
  BlobServiceClient,
  newPipeline,
} from '@azure/storage-blob';
import {from, Observable} from "rxjs";
import {environment, environment as env} from '../../../env/environment';
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {

  constructor(
      private httpClient: HttpClient,
  ) { }

  getUploadAvatarLink(accountId: number, filetype: string) {
    const params = new HttpParams()
      .set('userId', accountId)
      .set('filetype', filetype)
    return this.httpClient.get(`${environment.config.apiURL}/avatar/uploadURL`, { params: params })
  }

  uploadToAzure(file: File, uploadURL: string, userId: number, filetype: string): Observable<any> {
    const pipeline = newPipeline(new AnonymousCredential(), {
      retryOptions: { maxTries: 2 },
      keepAliveOptions: {
        enable: false,
      },
    });
    const blobServiceClient = new BlobServiceClient(uploadURL, pipeline);
    const containerClient = blobServiceClient.getContainerClient(env.config.blobConfig.avatarContainer);
    const blockBlobClient = containerClient.getBlockBlobClient(`user_${userId}.${filetype}`);
    return from(
        blockBlobClient.uploadData(file, {
          blockSize: 4 * 1024 * 1024,
          concurrency: 20,
          blobHTTPHeaders: {
            blobContentType: 'application/octet-stream',
          },
        })
    );
  }

  getAvatar(accountId: number) {
    return this.httpClient.get(`${environment.config.apiURL}/avatar/${accountId}`)
  }
  deleteAvatar(accountId: number) {
    return this.httpClient.delete(`${environment.config.apiURL}/avatar/${accountId}`)
  }
}
