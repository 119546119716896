import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../notifications/service/notifications.service';
import { NotificationType } from '../../notifications/enums/notification-type.enum';
import { debounceTime, tap } from 'rxjs/operators';
import { PageNotification } from '../../notifications/models/notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  showNotification: boolean = false;

  incommingNotification: PageNotification = {
      message: '',
      type: NotificationType.ERROR,
  };

  constructor(private notificationService: NotificationsService) {}

  ngOnInit(): void {
      this.notificationService.notifyRequest$
          .pipe(
              tap((notification: PageNotification) => {
                  this.incommingNotification = notification;
                  this.showNotification = true;
              }),
              debounceTime(5000),
              tap(() => {
                  this.showNotification = false;
              })
          )
          .subscribe();
  };

  onDismiss() {
    this.showNotification = false;
  }
};
