<h4 class="gul-font-color small-header">User avatar / Company logo</h4>
<div class="avatar-with-buttons">
  <div class="gul-img-container">
    <img *ngIf="url?.length; else noImage" class="avatar" [src]="url" alt="" height="50" width="50">

    <ng-template #noImage>
      <div class="gul-img-border avatar"></div>
    </ng-template>
  </div>

  <div class="avatar-buttons-container">
    <input #fileInput type="file" class="file-input" accept="image/x-png,image/gif,image/jpeg"
           (change)="handleInputFileChange($event)" />

    <div class="upload-messages-container">
      <span class="gul-upload-message">Upload avatar</span>
      <button class="classic-button" (click)="fileInput.click()">
        CHOOSE FILE
      </button>
      <span class="gul-upload-warning">Recommended size: 250 x 50 pixels</span>
      <span class="gul-upload-warning">The maximum file size allowed is 200KB</span>
    </div>

    <button class="classic-button red-button" (click)="removeImage()">REMOVE</button>
  </div>
</div>
