import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'acStatus',
})
export class AcStatusPipe implements PipeTransform {
  transform(status: string):  string {
    switch(status) {
      case 'STATUS1':
        return 'Over half quota available';
      case 'STATUS2':
        return 'Almost expired (less than 2 months remaining)';
      case 'STATUS3':
        return 'Expired / Overlimit';
      case 'STATUS4':
        return 'Almost expired (less than 1 month remaining)';
      case 'STATUS5':
        return 'Almost used all quota (less than 10 reports remaining / less than 100Mb remaining)';
      case 'STATUS6':
        return 'Less than half quota available';
      default:
        return 'No information about account status';
    }
  }
}
