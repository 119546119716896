import { Pipe, PipeTransform } from '@angular/core';
import { Account } from '../share/account/models/account.model';

@Pipe({
  name: 'serialNo',
})
export class SerialNoPipe implements PipeTransform {
  transform(account: Account): string {
    if (account.role === 'ADMIN') {
      return 'N/A';
    } else {
      return account.serialNumber;
    }
  }
}
