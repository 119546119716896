import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../env/environment';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = new URL(request.url);
    const apiUrl = new URL(environment.config.apiURL)
    if (requestUrl.origin !== apiUrl.origin)
      return next.handle(request);

    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
          this.authService.logout();
      } else if(err.status ==423){
        window.alert('Your account was locked! Contact system administrator');
        this.authService.logout();
      }
      return throwError(err);
    }));
  }
}
