import {
  Component,
  ElementRef,
  ViewChild,
  HostListener, Input,
} from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment as env } from '../../../env/environment';
import {UserModel} from "../../auth/user.model";
import packageJson from 'package.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  year = new Date().getFullYear();
  version = packageJson.version;
  aboutModal = false;
  @Input() user: UserModel;
  isDropdownVisible = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.isDropdownVisible) {
      if (!this.dropdown.nativeElement.contains(event.target)) {
        this.toggleDropdown();
      }
    }
  }

  constructor(private authService: AuthService) {}

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  logout() {
    this.authService.logout();
  }

  redirectToScanningStudio() {
    window.location.replace(env.config.scanningStudioUrl);
  }
}
